import React, { useEffect } from 'react';
import { Box, Flex, Button } from '@chakra-ui/react';
import ChatBox from './ChatBox';
import ChatInput from './ChatInput';

const ChatContainer = ({ messages, isLoading, username, onSendMessage, game_ref_id, isMuted, setIsMuted, questStatus, loadMsgs }) => {

    return (
        <Box width="70vw">
            <ChatBox messages={messages} username={username} isLoading={isLoading} loadMsgs={loadMsgs} />
            <Flex>
                <ChatInput onSubmit={onSendMessage} username={username} game_ref_id={game_ref_id} isLoading={isLoading} questStatus={questStatus}
                           isMuted={isMuted}
                           setIsMuted={setIsMuted}
                    />
            </Flex>
        </Box>
    );
};

export default ChatContainer;