import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';

const Logo = ({ imageSrc, altText, logoText, size = "40px", ...props }) => {
    return (
        <Box {...props}>
            {imageSrc ? (
                <Image src={imageSrc} alt={altText} height={size} /> // Using the size prop to control the height
            ) : (
                <Text fontSize="lg" fontWeight="bold">
                    {logoText}
                </Text>
            )}
        </Box>
    );
};


export default Logo;