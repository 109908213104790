import React, { useState, useEffect } from 'react';
import { Box, Button, Spinner, Input, FormLabel, Text, Heading, Image, Center, Flex } from "@chakra-ui/react";
import axios from 'axios';
import { storage, firestore } from '../auth/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';

const CreateCharacterForm = (props) => {
    const [file, setFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const [character, setCharacter] = useState(null);
    const [characterDoc, setCharacterDoc] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setLoading(true);
        setCharacter(null);

        const formData = new FormData();
        formData.append('character_sheet', file);
        formData.append('user_id', props.userId);

        try {
            const response = await axios.post('https://dungeon-gpt-api-9a9rj.ondigitalocean.app/character_parser', formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            setCharacter(response.data.parsed_character);
            setCharacterDoc(response.data.character_doc_id);
        } catch (error) {
            console.error("Error uploading file: ", error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (imageFile && character) {
            const uniqueId = uuidv4();
            const characterImageRef = ref(storage, `character_images/${character.name}_${uniqueId}`);

            const uploadTask = uploadBytesResumable(characterImageRef, imageFile);

            setLoadingImage(true);

            uploadTask.on('state_changed',
                (snapshot) => {
                    // Compute and log the upload progress as a percentage
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log(`Upload is ${progress}% done`);
                },
                (error) => {
                    console.log(error);
                    setLoadingImage(false);
                },
                () => {
                    // Handle successful uploads on complete
                    getDownloadURL(characterImageRef).then(url => {
                        setImageUrl(url);
                        // Once the download URL has been set, update the character document with the image URL
                        setDoc(doc(firestore, "characters", characterDoc), { image_url: url }, { merge: true });
                        setLoadingImage(false);
                        // Call the onCreate function with the updated character object
                        props.onCreate(character);
                    });
                }
            );
        }
    }, [imageFile, character]);


    return (
        <Box as="form" onSubmit={handleSubmit}>
            {!character &&
                <Box>
                    <FormLabel>Upload your PDF character sheet *This should take about a minute to complete.</FormLabel>
                    <Input type="file" accept=".pdf" onChange={handleFileChange} required disabled={loading} />
                    <Flex justifyContent="center" alignItems="center" mt={4}>
                        <Button bg="#FF4136" color="white" type="submit" isLoading={loading}>Parse Character</Button>
                    </Flex>
                </Box>
            }
            {character &&
                <Box>
                    {!imageUrl &&
                        <Box>
                            <FormLabel mt={3}>Upload a character image</FormLabel>
                            <Input type="file" accept="image/*" onChange={handleImageChange} disabled={loadingImage} />
                            <Flex justifyContent="center" alignItems="center" mt={4}>
                                <Button bg="#FF4136" color="white" isLoading={loadingImage}>Upload Image</Button>
                            </Flex>
                        </Box>
                    }
                    {imageUrl &&
                        <Center>
                            <Image borderRadius="full" boxSize="150px" src={imageUrl} alt={character.name} mb={4} />
                        </Center>
                    }

                    <Box display="grid" gridTemplateColumns="repeat(2, 1fr)" gap={6}>
                    <Box>
                        <Heading size="md">General Info</Heading>
                        <Text><b>Name:</b> {character.name}</Text>
                        <Text><b>Class:</b> {character.p_class}</Text>
                        <Text><b>Level:</b> {character.level}</Text>
                        <Text><b>Race:</b> {character.race}</Text>
                        <Text><b>Background:</b> {character.background}</Text>
                        <Text><b>Experience Points:</b> {character.experience_points}</Text>
                        <Text><b>Defenses:</b> {character.defenses}</Text>
                        <Text><b>Save Modifiers:</b> {character.save_modifiers}</Text>
                        <Text><b>Initiative:</b> {character.initiative}</Text>
                        <Text><b>Armor Class:</b> {character.armor_class}</Text>
                        <Text><b>Proficiency Bonus:</b> {character.proficiency_bonus}</Text>
                        <Text><b>Spell Slots:</b> {character.spell_slots}</Text>
                        <Text><b>Hit Points:</b> {character.hit_points}</Text>
                        <Text><b>Hit Dice:</b> {character.hit_dice}</Text>
                        <Text><b>Feats:</b> {character.feats}</Text>
                    </Box>
                    <Box>
                        <Heading size="md">Ability Scores & Modifiers</Heading>
                        {Object.keys(character.ability_scores).map((key) => (
                            <Text key={key}><b>{key}:</b> {character.ability_scores[key]} ({character.ability_modifiers[key]})</Text>
                        ))}
                            <br></br>
                            <Heading size="md">Attacks</Heading>
                            {Object.keys(character.attacks).map((key) => (
                                <Text key={key}><b>{key}:</b> {character.attacks[key]}</Text>
                            ))}
                            <br></br>
                            <Heading size="md">Cantrips</Heading>
                            {character.cantrips.map((cantrip, index) => (
                                <Text key={index}><b>Cantrip {index + 1}:</b> {cantrip}</Text>
                            ))}
                            <br></br>
                            <Heading size="md">Spells</Heading>
                            {character.spells.map((spell, index) => (
                                <Text key={index}><b>Spell {index + 1}:</b> {spell}</Text>
                            ))}
                    </Box>
                    <Box>
                        <Heading size="md">Skills</Heading>
                        {Object.keys(character.skills).map((key) => (
                            <Text key={key}><b>{key}:</b> {character.skills[key]}</Text>
                        ))}
                    </Box>
                    <Box>
                        <Heading size="md">Proficiencies</Heading>
                        {character.proficiencies.map((proficiency, index) => (
                            <Text key={index}><b>Proficiency {index + 1}:</b> {proficiency}</Text>
                        ))}
                    </Box>
                    <Box>
                        <Heading size="md">Feature Traits</Heading>
                        {character.features_traits.map((trait, index) => (
                            <Text key={index}><b>Trait {index + 1}:</b> {trait}</Text>
                        ))}
                    </Box>
                    <Box>
                        <Heading size="md">Equipment</Heading>
                        {character.equipment.map((item, index) => (
                            <Text key={index}><b>Item {index + 1}:</b> {item}</Text>
                        ))}
                    </Box>
                    <Box>
                        <Heading size="md">Personal Details</Heading>
                        {Object.keys(character.personal_details).map((key) => (
                            <Text key={key}><b>{key}:</b> {character.personal_details[key]}</Text>
                        ))}
                    </Box>
                    <Box>
                        <Heading size="md">Appearance</Heading>
                        {Object.keys(character.appearance).map((key) => (
                            <Text key={key}><b>{key}:</b> {character.appearance[key]}</Text>
                        ))}
                    </Box>
                    <Box>
                        <Heading size="md">Personality</Heading>
                        {Object.keys(character.personality).map((key) => (
                            <Text key={key}><b>{key}:</b> {character.personality[key]}</Text>
                        ))}
                    </Box>
                    <Box gridColumn="span 2">
                        <Heading size="md">Backstory</Heading>
                        <Text>{character.backstory}</Text>
                    </Box>
                    <Box gridColumn="span 2">
                        <Heading size="md">Additional Notes</Heading>
                        <Text>{character.additional_notes}</Text>
                    </Box>
                        </Box>
                        </Box>
            }
        </Box>
    );
};

export default CreateCharacterForm;
