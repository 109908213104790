import React, { useState } from 'react';
import {
  Stack,
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Checkbox,
  Button,
  Divider,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { signIn } from "../auth/firebase"; // You may need to adjust this import path


const LoginPage = ({ signInWithEmail, signUpWithEmail, navigate }) => {
  const [isSignIn, setIsSignIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [betaKey, setBetaKey] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let result;
    if (isSignIn) {
        result = await signInWithEmail(email, password);
    } else {
        result = await signUpWithEmail(email, password, username, betaKey);
    }
    setLoading(false);

    if (result.success) {
        navigate(`/profile`);
    } else {
        // Handle the error here
        console.error("Error:", result.error);
        setError(result.error); // Set a user-friendly error message
    }
};

  return (
      <Stack spacing="8">
        <Box boxShadow="md" p={8} bg={useColorModeValue('white', 'gray.800')} rounded="lg" border="1px"
             borderColor="gray.300">
          <Stack spacing="8" style={{position: 'relative', zIndex: 1}} textAlign="center">
            <Heading size={{base: 'xs', md: 'sm'}}>
              {isSignIn ? 'Log in to your account' : 'Create your account'}
            </Heading>
            <form onSubmit={handleSubmit}>
              <Stack spacing="5">
                {!isSignIn && (
                    <>
                      <FormControl>
                        <FormLabel htmlFor="betaKey">Beta Key</FormLabel>
                        <Input id="betaKey" type="text" value={betaKey} onChange={e => setBetaKey(e.target.value)}
                               isRequired/>
                      </FormControl>
                      <FormControl>
                        <FormLabel htmlFor="username">Username</FormLabel>
                        <Input id="username" type="text" value={username} onChange={e => setUsername(e.target.value)}
                               isRequired/>
                      </FormControl>
                    </>
                )}
                <FormControl>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input id="email" type="email" value={email} onChange={e => setEmail(e.target.value)} isRequired/>
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Input id="password" type="password" value={password} onChange={e => setPassword(e.target.value)}
                         isRequired/>
                </FormControl>
                {isSignIn && (
                    <HStack justify="space-between">
                      <Checkbox defaultChecked>Remember me</Checkbox>
                      <Button variant="text" size="sm" textDecoration="underline">
                        Forgot password?
                      </Button>
                    </HStack>
                )}
                <Divider/>
                {error && <Text color="red.500" mt={2} mb={2}>{error}</Text>}
                <Button type="submit" isLoading={isLoading} mt={4}>{isSignIn ? 'Sign In' : 'Sign Up'}</Button>
              </Stack>
            </form>
            <Divider/>
            <Text color="fg.muted" textDecoration="underline" cursor="pointer" onClick={() => {
              setIsSignIn(!isSignIn);
              setError(null);  // reset the error state when switching views
            }}
                  mt={4} textAlign="center">
              {isSignIn ? "Don't have an account? Sign up" : "Already have an account? Sign in"}
            </Text>
          </Stack>
        </Box>
      </Stack>
  );
};

export default LoginPage;
