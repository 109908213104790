import React, { useRef, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import ChatMessage from './ChatMessage';
import EllipsisLoading from './EllipsisLoading';

const ChatBox = ({ messages, username, isLoading, loadMsgs }) => {
    const chatBoxRef = useRef(null);

    const isOwnMessage = (messageUsername) => {
        return messageUsername === username;
    };

    const scrollToBottom = () => {
        if (chatBoxRef.current) {
            const chatContainer = chatBoxRef.current;
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    };

    const handleTypingDone = () => {
        scrollToBottom();
    };

    useEffect(() => {
        // Immediate scroll to bottom when messages update
        scrollToBottom();
    }, [messages]);

    return (
        <Box
            p={5} mb={4} borderRadius="md" height="70vh" overflowY="auto"
            ref={chatBoxRef}  // Attach the ref to this Box component
        >
            {messages && messages.map((message, i) => (
                <ChatMessage
                    key={i}
                    message={message.text}
                    sender={message.sender}
                    isOwnMessage={isOwnMessage(message.sender)}
                    audioUrl={message.audioUrl}
                    onTypingDone={handleTypingDone}
                    loadMsgs={loadMsgs}
                />
            ))}
            {isLoading && <EllipsisLoading />}
        </Box>
    );
};

export default ChatBox;



