import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import Typist from 'react-typist-component';

const ChatMessage = ({ message, sender, isOwnMessage, audioUrl, onTypingDone, loadMsgs }) => {
    const messageLines = message.split('\n');

    const renderMessage = (lines) => {
        console.log(message);
        if (sender === "Dungeon Master" && !loadMsgs.current.has(message)) {
            return (
                <Typist typingDelay={40} onTypingDone={onTypingDone}>
                    {lines.map((line, i) => (
                        <React.Fragment key={i}>
                            <Text fontSize="md" my={1}>{line}</Text>
                            {i !== lines.length - 1 && <Typist.Delay ms={100} />}
                        </React.Fragment>
                    ))}
                </Typist>
            );
        } else {
            return lines.map((line, i) => (
                <Text key={i} fontSize="md" my={1}>{line}</Text>
            ));
        }
    };

    return (
        <Flex justify={isOwnMessage ? 'flex-end' : 'flex-start'} my={2}>
            <Flex direction="row" align="center" maxWidth="70%">
                {!isOwnMessage && (
                    <Box minWidth="100px" mr={2}>
                        <Text fontSize="xs" color="gray.300">{sender}</Text>
                    </Box>
                )}
                <Box
                    bg={isOwnMessage ? 'blue.500' : 'gray.500'}
                    color="white"
                    p={2}
                    borderRadius="md"
                >
                    {renderMessage(messageLines)}
                </Box>
                {isOwnMessage && (
                    <Box minWidth="100px" ml={2}>
                        <Text fontSize="xs" color="gray.300">{sender}</Text>
                    </Box>
                )}
                {!isOwnMessage && audioUrl && (
                    <audio controls src={audioUrl} style={{ marginLeft: '10px' }} />
                )}
            </Flex>
        </Flex>
    );
};

export default ChatMessage;

