import React, { useState } from 'react';
import { Heading, Flex, Spacer, Button, Image, Link, Box } from '@chakra-ui/react';
import { signInWithEmail, signUpWithEmail, signIn, signUserOut, auth } from '../auth/firebase'; // Adjust this import to match your file structure
import SignInSignUpModal from '../auth/SignInSignUpModal';
import Logo from '../components/Logo';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ title }) => {
  const [user] = useAuthState(auth);
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

    return (
        <Flex p={2} align="center" boxShadow="md" height="60px">
            <Logo imageSrc="/dgpt_B10_crop.png" altText="Logo"/>
            <Box>
                <Button variant="link" onClick={() => navigate('/profile')} mr={4}>Add
                    Character</Button> {/* Here's the added margin */}
                <Button variant="link" onClick={() => navigate('/profile')}>Quests</Button>
                {/*<Button variant="link" onClick={() => navigate('/profile')}>FAQ</Button>*/}
            </Box>
            <Heading ml={2} size="md">{title}</Heading>
            <Spacer/>
            {user ? (
                <>
                    <Button bg="#FF4136" color="white" onClick={signUserOut} size="sm">Sign Out</Button>
                </>
            ) : (
                <>
                    <Button bg="#FF4136" color="white" onClick={openModal} size="sm">Sign In</Button>
                    <SignInSignUpModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        signIn={signIn}
                        signInWithEmail={signInWithEmail}
                        signUpWithEmail={signUpWithEmail}
                        navigate={navigate}
                    />
                </>
            )}
        </Flex>
    );
};

export default Navbar;
