import React, { useState } from 'react';
import { Button, Flex, Heading, Text, SimpleGrid, Box, VStack, Icon, useColorModeValue } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Card = (props) => <Box borderWidth="1px" borderRadius="lg" padding="10px" {...props} />;
const CardHeader = (props) => <Flex justify="center" align="center" py={4} {...props} />;
const CardBody = (props) => <Box py={4} px={2} textAlign="center" {...props} />;
const CardFooter = (props) => <Box py={4} textAlign="center" {...props} />;


const Home = () => {
    const [experience, setExperience] = useState('');
    const [playStyle, setPlayStyle] = useState('');
    const [adventureFocus, setAdventureFocus] = useState('');
    const [partySize, setPartySize] = useState('');
    const navigate = useNavigate();

    const handleCreateRoom = async () => {
        try {
            // Fetch a new game reference ID from your API
            const response = await axios.post(
                'http://localhost:8000/create_room/',
                {
                    experience,
                    playStyle,
                    adventureFocus,
                    partySize,
                },
                {
                    headers: {
                        'ngrok-skip-browser-warning': 'any',
                    }
                }
            );

            // Use the received game reference ID to navigate to the newly created room
            const newRoomId = response.data.game_ref_id;
            navigate(`/rooms/${newRoomId}`);
        } catch (error) {
            console.error("Failed to create room:", error);
        }
    };

    const experienceOptions = [
        { label: 'Beginner', value: 'beginner', description: 'Perfect for newcomers. The DM will guide you through the rules.' },
        { label: 'Intermediate', value: 'intermediate', description: 'For those who know the rules but prefer a straightforward quest.' },
        { label: 'Advanced', value: 'advanced', description: 'For experienced players seeking a complex, immersive experience. (Alpha)' }
    ];
    const playStyleOptions = [
        { label: 'Casual', value: 'casual', description: 'A fun experience prioritizing creativity over rules.' },
        { label: 'By the Book', value: 'strict', description: 'A quest following 5e rules as closely as possible.' }
    ];
    const adventureFocusOptions = [
        { label: 'Action-Focused', value: 'action', description: 'Prioritizes combat over storylines.' },
        { label: 'Balanced', value: 'balanced', description: 'Equally balances storyline and combat.' },
        { label: 'Story-Driven', value: 'story', description: 'Focuses on quest storyline, NPC conversations, and lore.' }
    ];
    const partySizeOptions = [
        { label: 'Solo', value: 'solo', description: 'Just you and the DM.' },
        { label: '2 Players', value: 'two', description: 'Invite one player.' },
        { label: '3 Players', value: 'three', description: 'Invite two players.' },
        { label: '4 Players', value: 'four', description: 'Invite three players.' },
        { label: '5 Players', value: 'five', description: 'Invite four players.' }
    ];

    const selectedColor = useColorModeValue('green.500', 'green.200');

    const renderOptions = (options, selectedValue, setSelectedValue) => (
        options.map((option) => (
            <Card key={option.value}>
                <CardHeader>
                    <Heading size="md">{option.label}</Heading>
                    {selectedValue === option.value && <CheckCircleIcon ml={2} color={selectedColor} />}
                </CardHeader>
                <CardBody>
                    <Text>{option.description}</Text>
                </CardBody>
                <CardFooter>
                    <Button onClick={() => setSelectedValue(selectedValue === option.value ? null : option.value)}>
                        {selectedValue === option.value ? 'De-select' : 'Select'}
                    </Button>
                </CardFooter>
            </Card>
        ))
    );

    return (
        <Flex direction="column" align="center" justify="center" height="100vh" p={4}>
            <Heading mb={4}>Design Your Dungeon Master</Heading>

            <Heading size="lg" mb={2}>Experience Level</Heading>
            <SimpleGrid spacing={4} templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' }}>
                {renderOptions(experienceOptions, experience, setExperience)}
            </SimpleGrid>

            <Heading size="lg" mt={6} mb={2}>Play Style</Heading>
            <SimpleGrid spacing={4} templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}>
                {renderOptions(playStyleOptions, playStyle, setPlayStyle)}
            </SimpleGrid>

            <Heading size="lg" mt={6} mb={2}>Adventure Focus</Heading>
            <SimpleGrid spacing={4} templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' }}>
                {renderOptions(adventureFocusOptions, adventureFocus, setAdventureFocus)}
            </SimpleGrid>

            <Heading size="lg" mt={6} mb={2}>Party Size</Heading>
            <SimpleGrid spacing={4} templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(5, 1fr)' }}>
                {renderOptions(partySizeOptions, partySize, setPartySize)}
            </SimpleGrid>

            <Button
                colorScheme="blue"
                onClick={handleCreateRoom}
                isDisabled={!experience || !playStyle || !adventureFocus || !partySize}
                mt={6}
            >
                Create Room
            </Button>
        </Flex>
    );
};

export default Home;
