import React, { useState } from 'react';
import Dice from './Dice';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    HStack,
    Text,
    Box
} from '@chakra-ui/react';

const DiceRoller = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [rollResult, setRollResult] = useState(null);
    const [rolledDice, setRolledDice] = useState(null);

    const handleDiceRoll = (value, sides) => {
        setRollResult(value);
        setRolledDice(sides);
    };

    return (
        <>
            <Button onClick={() => setIsOpen(true)}>Dice</Button>
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay />
                <ModalContent maxW="lg">
                    <ModalHeader>Dice Roller</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <HStack spacing={4}>
                            {[4, 6, 8, 10, 12, 20, 100].map((sides) => (
                                <Dice key={sides} sides={sides} onRoll={handleDiceRoll} />
                            ))}
                        </HStack>
                        {rollResult && (
                            <Box mt={4} textAlign="center">
                                <Text fontSize="xl">D{rolledDice} rolled:</Text>
                                <Text fontSize="3xl" fontWeight="bold" mt={2}>{rollResult}</Text>
                            </Box>
                        )}

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DiceRoller;

