import React, { useState, useEffect } from 'react';
import {
    VStack,
    Box,
    Button,
    Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Grid,
    Card,
    CardBody,
    Text,
    Heading,
    CardHeader,
    Flex,
    Avatar,
    IconButton,
    Menu,
    MenuItem,
    MenuButton,
    MenuList,
    Divider
} from '@chakra-ui/react';
import CreateCharacterForm from '../components/CreateCharacterForm';
import { auth, firestore } from '../auth/firebase';
import { collection, getDocs, query, where, doc, deleteDoc } from 'firebase/firestore';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaShieldAlt, FaHeart, FaCoins } from 'react-icons/fa';
import FeaturedQuests from "../components/FeaturedQuests";



const Profile = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [characters, setCharacters] = useState([]);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in
                setUserId(user.uid);
            } else {
                // No user is signed in
                setUserId(null);
            }
        });

        return () => unsubscribe();
    }, []);

    const handleModalClose = () => {
        // Call the original onClose function (probably updates a state to close the modal)
        onClose();

        // Your custom logic here
        fetchCharacters();
    }

    const fetchCharacters = async () => {
        const characterCollection = collection(firestore, 'characters');
        const characterQuery = query(characterCollection, where('user_id', '==', userId));
        const characterSnapshot = await getDocs(characterQuery);
        const characters = characterSnapshot.docs.map(doc => ({
            id: doc.id, // Including the document ID here
            ...doc.data()
        }));
        setCharacters(characters);
    }

    useEffect(() => {
        if (userId) {
            fetchCharacters();
        }
    }, [userId]);

    const handleCharacterCreation = (character) => {
        fetchCharacters();
        onClose();
    }

    const handleDeleteCharacter = async (character) => {
    try {
      const characterRef = doc(firestore, 'characters', character.id); // Referencing the document by character ID
      await deleteDoc(characterRef); // Deleting the document

      // Optionally, you can refresh the characters list after deletion
      fetchCharacters();
    } catch (error) {
      console.error("Error deleting character:", error);
    }
  };

    return (
        <>
        <Box padding="4">
            <Flex justifyContent="center" marginBottom="4">
                <VStack>
                    <Heading as="h1" size="s" textAlign="center">
                    Note for Beta: There is no manual character creation at this time. We recommend creating character sheets on dndbeyond. Please ensure your character sheet is accurate before loading, with the proper armor and weapons equipped.
                </Heading>
                    <Button bg="#FF4136" color="white"
                        onClick={onOpen}>
                    Add New Character
                </Button>
                </VStack>
            </Flex>
            <Heading textAlign="center" marginBottom="4">Your Characters</Heading>

            <Modal isOpen={isOpen} onClose={handleModalClose}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalHeader>Create New Character</ModalHeader>
                    <ModalCloseButton/>
                    <ModalBody>
                        <CreateCharacterForm onCreate={handleCharacterCreation} userId={userId}/>
                    </ModalBody>
                </ModalContent>
            </Modal>

            <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                {characters.map((character, index) => (
                    <Card key={index} maxW='md' paddingX="4">
                        <CardHeader>
                            <Flex justifyContent="space-between" alignItems="center">
                                <Flex alignItems="center">
                                    <Avatar size="xl" name={character.name} src={character.image_url}/>
                                    <Box ml={4}>
                                        <Heading size='lg'>{character.name}</Heading>
                                        <Text fontSize="lg">{character.p_class}, Level {character.level}</Text>
                                    </Box>
                                </Flex>
                                <Menu>
                                    <MenuButton
                                        as={IconButton}
                                        variant='ghost'
                                        colorScheme='gray'
                                        aria-label='See menu'
                                        icon={<BsThreeDotsVertical/>}
                                    />
                                    <MenuList>
                                        <MenuItem
                                            onClick={() => handleDeleteCharacter(character)}>Delete</MenuItem>
                                    </MenuList>
                                </Menu>
                            </Flex>
                        </CardHeader>
                        <CardBody>
                            <Flex justifyContent="space-between">
                                <Box>
                                    <Flex alignItems="center">
                                        <FaShieldAlt/>
                                        <Text fontSize="xl"> {character.armor_class}</Text>
                                    </Flex>
                                    <Text fontSize="sm">Armor Class</Text>
                                </Box>
                                <Box>
                                    <Flex alignItems="center">
                                        <FaHeart/>
                                        <Text fontSize="xl" ml={2}> {character.hit_points}</Text>
                                    </Flex>
                                    <Text fontSize="sm">Hit Points</Text>
                                </Box>
                            </Flex>
                        </CardBody>
                    </Card>
                ))}
            </Grid>
        </Box>
            <FeaturedQuests />
            </>
    );
};

export default Profile;
