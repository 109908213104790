import React, { useState, useEffect, useRef } from 'react';
import { Input, Button, Flex } from '@chakra-ui/react';
import { useWhisper } from '@chengsokdara/use-whisper'
import { firestore, auth } from '../../auth/firebase';  // adjust this path to point to your firebase configuration file
import axios from 'axios'; // Add this line
import { collection, doc, updateDoc, arrayUnion } from "firebase/firestore";
import DiceRoller from '../../components/DiceRoller/DiceRoller';

const ChatInput = ({ onSubmit, username, game_ref_id, isLoading, isMuted, setIsMuted, questStatus }) => {
    const [message, setMessage] = useState('');

    const {
        recording,
        speaking,
        transcribing,
        transcript,
        pauseRecording,
        startRecording,
        stopRecording,
    } = useWhisper({
        apiKey: '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',
    })

    // Listen for changes to `transcript.text`
    useEffect(() => {
        setMessage(transcript.text || '');
    }, [transcript.text]);

    const handleRecordingClick = () => {
        if (recording) {
            stopRecording();
            // We're now setting the transcript in a useEffect hook, so no need to do it here
        } else {
            startRecording();
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Call the passed onSubmit function
        onSubmit(message);

        // Define the message object
        const newMessage = { text: message, sender: username };

        // Save the message to Firebase
        const gameSessionsCollection = collection(firestore, 'gameSessions');
        const gameSessionDoc = doc(gameSessionsCollection, game_ref_id);
        await updateDoc(gameSessionDoc, {
            'conversation_history': arrayUnion(newMessage)
        });

        // Clear the input field
        setMessage('');
    };


    return (
        <Flex as="form" onSubmit={handleSubmit} mt={4} width="70vw" flexGrow={1}>
            <Input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type a message"
                flexGrow={1}
            />
            <DiceRoller />
            <Button ml={2} onClick={handleRecordingClick}>
                {recording ? 'Stop' : 'Speak'}
            </Button>
            <Button ml={2} onClick={() => setIsMuted(!isMuted)}>
                {isMuted ? "Unmute" : "Mute"}
            </Button>
            <Button
                ml={2}
                bg="#FF4136"
                color="white"
                type="submit"
                isLoading={isLoading}
                isDisabled={isLoading || questStatus === 'Complete'}>
                Send
            </Button>
        </Flex>
    );
};

export default ChatInput;
