// App.js
import React from 'react';
import { Box, ChakraProvider, extendTheme } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import { Route, Routes, createBrowserRouter, RouterProvider } from 'react-router-dom';
import SignInSignUpModal from "./auth/SignInSignUpModal";
import { withAuth } from './auth/withAuth';
import {signIn, signInWithEmail, signUpWithEmail, signUserOut} from "./auth/firebase";
import { useNavigate } from 'react-router-dom';


import SignInSignUp from './pages/SignInSignUp';
import Home from './pages/Home';
import GameRoom from './pages/GameRoom';
import Profile from './pages/Profile';
import LandingPage from "./pages/LandingPage";
import LoginPage from "./pages/LoginPage";

export const HOME = "/home";
export const SIGN_IN_SIGN_UP = "/sisu";
export const ROOM = "/rooms/:game_ref_id";
export const PROFILE = "/profile";
export const LANDING_PAGE = "/";
export const LOGIN = "/login";

const ProtectedProfile = withAuth(Profile);
const ProtectedRoom = withAuth(GameRoom);

const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
};

const theme = extendTheme({
    config,
    colors: {
        gray: {
            800: "#1f2029",
            900: "#18181b",
        }
    }
});

const Layout = ({ children }) => {
  return (
    <>
      <Navbar title="" />
      {children}
    </>
  );
};

function Root() {
    const navigate = useNavigate();
  return (
    <Layout>
      <Routes>
        <Route path={LANDING_PAGE} element={<LandingPage />} />
        <Route path={SIGN_IN_SIGN_UP} element={<SignInSignUp />} />
        <Route path={HOME} element={<Home />} />
        <Route path={ROOM} element={<ProtectedRoom />} />
        <Route path={PROFILE} element={<ProtectedProfile />} />
        <Route path={LOGIN} element={<LoginPage signInWithEmail={signInWithEmail} signUpWithEmail={signUpWithEmail} navigate={navigate} />} />
      </Routes>
    </Layout>
  );
}

// Router instance defined in the same file, no circular dependency issue
const router = createBrowserRouter([
    { path: "*", element: <Root /> },
]);

function App() {
  return (
    <ChakraProvider theme={theme}>
      <RouterProvider router={router}>
        <Root />
      </RouterProvider>
    </ChakraProvider>
  );
}

export default App;

