import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Dice = ({ sides, onRoll }) => {

    const rollDice = () => {
        const result = Math.floor(Math.random() * sides) + 1;
        onRoll(result, sides);
    };

    return (
        <Box p={3} borderWidth={1} borderRadius="lg" onClick={rollDice} cursor="pointer">
            <Text fontSize="lg">D{sides}</Text>
        </Box>
    );
};

export default Dice;
