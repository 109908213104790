// GameRoom.js

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Text, Box, VStack, Spinner, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Textarea, Rating, Heading, Image, Flex, List, ListItem, Icon, SimpleGrid, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, HStack, Divider } from '@chakra-ui/react';
import { FaHeart, FaShieldAlt, FaMagic } from "react-icons/fa";
import ChatContainer from '../components/ChatBox/ChatContainer';
import UsernameInput from '../components/ChatBox/UsernameInput';
import RulesChat from '../components/ChatBox/RulesChat';
import axios from 'axios';
import { onSnapshot, query, orderBy, collection, doc, updateDoc, arrayUnion, getDoc } from "firebase/firestore";
import { firestore, auth } from '../auth/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';


function CharacterSheet({ characterSheet }) {
    if (!characterSheet) return <div>Loading...</div>;

    return (
        <Box border="1px solid" borderColor="gray.300" borderRadius="lg" padding="4" pl={8} overflowY="auto" height="100%">
            {/* Basic Character Info */}
            <Flex alignItems="center" mb={4}>
                <Image borderRadius="full" boxSize="100px" src={characterSheet.image_url} alt={characterSheet.name} />
                <Box ml={4}>
                    <Heading>{characterSheet.name}</Heading>
                    <Text>{characterSheet.p_class}, Level {characterSheet.level}, {characterSheet.race}</Text>
                </Box>
            </Flex>

            <Flex mt={4} justifyContent="center">
                <Box mr={4} alignItems="center">
                    <Flex alignItems="center">
                        <Icon as={FaShieldAlt} />
                        <Text ml={2} fontSize="xl">{characterSheet.armor_class}</Text>
                    </Flex>
                    <Text fontSize="sm" textAlign="center">Armor Class</Text>
                </Box>
                <Box mr={4} alignItems="center">
                    <Flex alignItems="center">
                        <Icon as={FaHeart} />
                        <Text ml={2} fontSize="xl">{characterSheet.current_hp}/{characterSheet.hit_points}</Text>
                    </Flex>
                    <Text fontSize="sm" textAlign="center">Hit Points</Text>
                </Box>
                <Box alignItems="center">
                    <Flex alignItems="center">
                        <Icon as={FaMagic} />
                        <Text ml={2} fontSize="xl">{characterSheet.spell_slots}</Text>
                    </Flex>
                    <Text fontSize="sm" textAlign="center">Spell Slots</Text>
                </Box>
            </Flex>

            <br></br>
            <Divider />

            <Accordion defaultIndex={[0]} allowMultiple>
                {/* Attacks */}
                <AccordionItem>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">Attacks</Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <List>
                            {Object.entries(characterSheet.attacks)
                                .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                                .map(([attackName, attackDetail]) => (
                                    <ListItem key={attackName}>
                                        <strong>{attackName}:</strong> {attackDetail}
                                    </ListItem>
                                ))}
                        </List>
                    </AccordionPanel>
                </AccordionItem>

                {/* Skills and Abilities */}
                <AccordionItem>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">Skills and Abilities</Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <SimpleGrid columns={2} spacing={10}>
                            <Box>
                                <Heading size="md">Skills:</Heading>
                                <List>
                                    {Object.entries(characterSheet.skills)
                                        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                                        .map(([key, value]) => (
                                            <ListItem key={key}>{key}: {value}</ListItem>
                                        ))}
                                </List>
                            </Box>
                            <Box>
                                <Heading size="md">Abilities:</Heading>
                                <List>
                                    {Object.entries(characterSheet.ability_scores)
                                        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                                        .map(([key, value]) => (
                                            <ListItem key={key}>
                                                {key}: {value} (Modifier: {characterSheet.ability_modifiers[key]})
                                            </ListItem>
                                        ))}
                                </List>
                            </Box>
                        </SimpleGrid>
                    </AccordionPanel>
                </AccordionItem>

                {/* Equipment and Inventory */}
                {/* Equipment and Inventory */}
                <AccordionItem>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">Equipment and Inventory</Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <List>
                            {characterSheet.equipment.sort().map((item, index) => (
                                <ListItem key={index}>{item}</ListItem>
                            ))}
                        </List>
                    </AccordionPanel>
                </AccordionItem>

                {/* Spells and Magic */}
                <AccordionItem>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">Spells</Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <List>
                            {characterSheet.spells.sort().map((spell, index) => (
                                <ListItem key={index}>{spell}</ListItem>
                            ))}
                        </List>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">Cantrips</Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <List>
                            {characterSheet.cantrips.sort().map((cantrips, index) => (
                                <ListItem key={index}>{cantrips}</ListItem>
                            ))}
                        </List>
                    </AccordionPanel>
                </AccordionItem>

                {/* Personal Details */}
                <AccordionItem>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">Personal Details</Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <SimpleGrid columns={2} spacing={10}>
                            <List>
                                {Object.entries(characterSheet.personal_details)
                                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                                    .map(([key, value]) => (
                                        key !== 'CHARACTER IMAGE' && <ListItem key={key}>{key}: {value}</ListItem>
                                    ))}
                            </List>
                        </SimpleGrid>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">
                            Features & Traits
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <List>
                            {characterSheet.features_traits.sort().map((feature, idx) => (
                                <ListItem key={idx}>{feature}</ListItem>
                            ))}
                        </List>
                    </AccordionPanel>
                </AccordionItem>

                {/* Actions */}
                <AccordionItem>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">
                            Actions
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <List>
                            {characterSheet.actions.sort().map((action, idx) => (
                                <ListItem key={idx}>{action}</ListItem>
                            ))}
                        </List>
                    </AccordionPanel>
                </AccordionItem>

                {/* Bonus Actions */}
                <AccordionItem>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">
                            Bonus Actions
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <List>
                            {characterSheet.bonus_actions.sort().map((bonus, idx) => (
                                <ListItem key={idx}>{bonus}</ListItem>
                            ))}
                        </List>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    );
}


function GameRoom() {
    const [user] = useAuthState(auth);
    const user_id = user ? user.uid : null;
    const {game_ref_id} = useParams();
    const [username, setUsername] = useState('Entire Group');
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [audioSrc, setAudioSrc] = useState(null);
    const [audioQueue, setAudioQueue] = useState([]);
    const [gameIsLoading, setGameIsLoading] = useState(false);
    const [showChat, setShowChat] = useState(false);
    const [questStatus, setQuestStatus] = useState('Active');
    const [showRatingModal, setShowRatingModal] = useState(false);
    const [rating, setRating] = useState(0);
    const [gameStarted, setGameStarted] = useState(false);
    const [characterSheet, setCharacterSheet] = useState(null);
    const [volume, setVolume] = useState(0.75);
    const [isPlaying, setIsPlaying] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const processedDMMsgs = useRef(new Set());
    const loadMsgs = useRef(new Set());
    const [initialLoadDone, setInitialLoadDone] = useState(false);
    const initialLoadDoneRef = useRef(false);  // New ref


    // Handle start game
    const handleStartGame = async () => {
        try {
            setGameIsLoading(true);
            // Include the game_ref_id in the endpoint URL
            const url = `https://dungeon-gpt-api-9a9rj.ondigitalocean.app/game_ref_id/${game_ref_id}`;
            const response = await axios.get(url);
            setGameIsLoading(false);
            setGameStarted(true);
            setShowChat(true);
        } catch (error) {
            console.error("Failed to start game:", error);
            setGameIsLoading(false);
        }
    };


    useEffect(() => {
        if (game_ref_id) {
            // Initialize Firestore
            const gameSessionsCollection = collection(firestore, 'gameSessions');
            const gameSessionDoc = doc(gameSessionsCollection, game_ref_id);

            // Create a listener for the document with onSnapshot
            const unsub = onSnapshot(gameSessionDoc, async (doc) => {
                const data = doc.data();

                // Update the questStatus state
                if (data.quest_status === 'Complete') {
                    setQuestStatus('Complete');
                }

                if (data && Array.isArray(data.conversation_history)) {
                    setMessages(data.conversation_history);
                    if (!initialLoadDoneRef.current) {
                        initialLoadDoneRef.current = true;
                        loadMsgs.current = new Set(data.conversation_history.map(msg => msg.text));
                    }

                    // Initialize processedDMMsgs with the current messages when the component first mounts
                    if (processedDMMsgs.current.size === 0) {
                        processedDMMsgs.current = new Set(data.conversation_history.map(msg => msg.text));
                        return;
                    }

                    // Generate audio for the new message(s)
                    const dmMessages = data.conversation_history.filter(message => message.sender === 'Dungeon Master');

                    for (const message of dmMessages) {
                        // Check if the message has already been processed
                        if (!processedDMMsgs.current.has(message.text)) {

                            if (!message.audioSrc) {
                                const lines = message.text.split('\n').filter(line => line.trim() !== '');
                                const audioResponses = new Array(lines.length);

                                await Promise.all(lines.map(async (line, index) => {
                                    if (!processedDMMsgs.current.has(line)) {
                                        // Mark this line as processed
                                        processedDMMsgs.current.add(line);
                                        const audioOptions = {
                                            headers: {
                                                'accept': 'audio/mpeg',
                                                'xi-api-key': '38dc2fd818eddb7b7227eb4d0c824c54',
                                                'Content-Type': 'application/json'
                                            },
                                            responseType: 'blob'
                                        };

                                        try {
                                            const audioResponse = await axios.post('https://api.elevenlabs.io/v1/text-to-speech/2EiwWnXFnvU5JabPnv8n?optimize_streaming_latency=0', {
                                                text: line,
                                                model_id: "eleven_monolingual_v1",
                                                voice_settings: {
                                                    stability: 1.0,
                                                    similarity_boost: 1.0
                                                }
                                            }, audioOptions)
                                            console.log(audioResponse);
                                            const audioUrl = URL.createObjectURL(audioResponse.data);
                                            console.log(audioUrl);
                                            audioResponses[index] = audioUrl;  // Store the audio response in the array by line index
                                            console.log(audioResponses);
                                        } catch (err) {
                                            console.log('Error generating audio: ', err);
                                        }
                                    }
                                }));

                                const filledAudioResponses = audioResponses.filter(url => !!url);
                                console.log(filledAudioResponses);
                                // After all lines have been processed, append the audio URLs in order to the main queue
                                setAudioQueue(oldQueue => [...oldQueue, ...filledAudioResponses]);

                                // Mark this message as processed
                                processedDMMsgs.current.add(message.text);
                            }
                        }
                    }
                }
            });

            // Cleanup the listener when the component is unmounted
            return () => {
                unsub();
            }
        }
    }, [game_ref_id]);


    useEffect(() => {
        if (questStatus === 'Complete') {
            setShowRatingModal(true);
        }
    }, [questStatus]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const gameSessionsCollection = collection(firestore, 'gameSessions');
                const gameSessionDoc = doc(gameSessionsCollection, game_ref_id);
                const gameDoc = await (getDoc(gameSessionDoc));
                if (gameDoc.exists()) {
                    const gameData = gameDoc.data();
                    if (gameData.quest_status === 'Active') {
                        setGameStarted(true);
                        setShowChat(true);
                    }
                }
            } catch (error) {
                console.error("Failed to fetch game data:", error);
            }
        };

        fetchData();
    }, [game_ref_id]);


    const handleSendMessage = (messageText) => {
        setIsLoading(true);

        // Define the user's message object
        const userMessage = {text: messageText, sender: username};

        // Access the Firestore collection and document
        const gameSessionsCollection = collection(firestore, 'gameSessions');
        const gameSessionDoc = doc(gameSessionsCollection, game_ref_id);

        // Fetch the current conversation history from Firestore
        getDoc(gameSessionDoc).then(docSnapshot => {
            if (docSnapshot.exists()) {
                // Extract the current conversation history
                const currentHistory = docSnapshot.data().conversation_history || [];

                // Append the new message
                const updatedHistory = [...currentHistory, userMessage];

                // Update the document with the new conversation history
                updateDoc(gameSessionDoc, {
                    'conversation_history': updatedHistory
                }).then(() => {
                    // After updating, proceed with the fetch call
                    const requestOptions = {
                        method: 'POST',
                        headers: {'Content-Type': 'application/json'},
                        body: JSON.stringify({
                            chat_input: {input_text: username + ": " + messageText},
                            game_room_input: {game_room_id: game_ref_id}
                        })
                    };

                    fetch('https://dungeon-gpt-api-9a9rj.ondigitalocean.app/dm_step/', requestOptions)
                        .then(response => response.json())
                        .then(async data => {
                            const dmMessage = {
                                text: data.response,
                                sender: 'Dungeon Master',
                                audioSrc: null,
                            };

                            const requestOptions2 = {
                                method: 'POST',
                                headers: {'Content-Type': 'application/json'},
                                body: JSON.stringify({game_room_id: game_ref_id})
                            }
                            await fetch('https://dungeon-gpt-api-9a9rj.ondigitalocean.app/dm_cleanup/', requestOptions2);

                            setIsLoading(false);
                        });
                });
            } else {
                console.error("Document doesn't exist!");
            }
        });
    };


    const playNextAudio = () => {
        // Remove the first element of the queue
        setAudioQueue(oldQueue => oldQueue.slice(1));
    };

    useEffect(() => {
    if (!gameStarted || !user_id) return;

    const gameSessionsCollection = collection(firestore, 'gameSessions');
    const gameSessionDoc = doc(gameSessionsCollection, game_ref_id);
    const unsubscribe = onSnapshot(gameSessionDoc, (doc) => {
        if (doc.exists()) {
            const currentUserCharacterSheet = doc.data().characters.characters[user_id];
            if (currentUserCharacterSheet) {
                setCharacterSheet(currentUserCharacterSheet);
                setUsername(currentUserCharacterSheet.name);
            } else {
                console.log("Character sheet not found for user:", user_id);
            }
        }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
}, [game_ref_id, user_id, gameStarted]);


    return (
        <VStack p={4}>
            <Box display="flex" flexDirection="row" flexGrow={1} justifyContent="center" alignItems="center">
                {gameIsLoading ? (
                    <Spinner/> // Loading spinner
                ) : (
                    <>
                        {!showChat ? (
                            <Button bg="#FF4136" color="white" onClick={handleStartGame}>Start Game</Button> // Start button in the center
                        ) : (
                            <Flex alignItems="stretch">
                                <Box width="30vw" mr={4} ml={4} border="1px" borderColor="gray.200">
                                    <CharacterSheet characterSheet={characterSheet}/>
                                </Box>
                                <Box mr={4} border="1px" borderColor="gray.200" flexGrow={1}>
                                    <ChatContainer
                                                messages={messages}
                                                isLoading={isLoading}
                                                username={username}
                                                onSendMessage={handleSendMessage}
                                                game_ref_id={game_ref_id}
                                                isMuted={isMuted}
                                                setIsMuted={setIsMuted}
                                                questStatus={questStatus}
                                                loadMsgs={loadMsgs}
                                    />
                                    <audio
                                        id="audio-player"
                                        src={audioQueue[0]}
                                        autoPlay
                                        onEnded={playNextAudio}
                                        muted={isMuted}
                                    />
                                </Box>
                            </Flex>
                        )}
                    </>
                )}
            </Box>
            <Modal isOpen={showRatingModal} onClose={() => setShowRatingModal(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Quest Ended</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <p>The quest has ended.</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setShowRatingModal(false)}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </VStack>
    );
}

export default GameRoom;
