import React from 'react';
import { auth } from './firebase'; // Import your auth object
import { Navigate } from 'react-router-dom';

export const withAuth = (ComponentToProtect) => {
  return class extends React.Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        authenticated: false,
      };
    }

    componentDidMount() {
      auth.onAuthStateChanged((user) => {
        if (user) {
          this.setState({ loading: false, authenticated: true });
        } else {
          this.setState({ loading: false, authenticated: false });
        }
      });
    }

    render() {
      const { loading, authenticated } = this.state;

      if (loading) {
        return <div>Loading...</div>; // You can replace this with a loading component
      }

      if (!authenticated) {
        return <Navigate to="/login" />;
      }

      return <ComponentToProtect {...this.props} />;
    }
  };
};
