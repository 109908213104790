import React, { useState, useEffect } from 'react';
import {collection, getDocs, query, where} from "firebase/firestore";
import {auth, firestore} from "../auth/firebase";
import {
  Box, VStack, Heading, Flex, Button, Image, Text, Modal,
  ModalOverlay, ModalContent, ModalHeader, ModalCloseButton,
  ModalBody, FormControl, FormLabel, Select, Divider, useDisclosure
} from '@chakra-ui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const FeaturedQuests = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [quests, setQuests] = useState([]);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [characters, setCharacters] = useState([]);
    const [selectedQuest, setSelectedQuest] = useState(null);
    const [userId, setUserId] = useState(null);
    const [selectedCharacterId, setSelectedCharacterId] = React.useState(null);
    const [experienceLevel, setExperienceLevel] = useState('Experienced'); // default value
    const [partySize, setPartySize] = useState('Solo'); // default value
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUserId(user.uid);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (userId) {
            fetchCharacters();
        }
    }, [userId]);

    const fetchCharacters = async () => {
        const characterCollection = collection(firestore, 'characters');
        const characterQuery = query(characterCollection, where('user_id', '==', userId));
        const characterSnapshot = await getDocs(characterQuery);
        const charactersList = characterSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        setCharacters(charactersList);
    };

    useEffect(() => {
        const fetchQuests = async () => {
            const questCollection = collection(firestore, "quests");
            const questSnapshot = await getDocs(questCollection);
            const questList = questSnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id
            }));
            setQuests(questList);
        };

        fetchQuests();
    }, []);

    const handleCreateRoom = async () => {
        setIsLoading(true);
        if (!selectedCharacterId) {
            // You can show an error message to the user, e.g., using a toast notification
            console.error("Character must be selected before creating a room.");
            setIsLoading(false);
            return;
        }

        try {
            // Build the request payload
            const payload = {
                character_id: selectedCharacterId,
                experience: experienceLevel,
                party_size: partySize,
                user_id: userId, // assuming you've retrieved this earlier
                quest_id: selectedQuest?.id
            };

            // Send the POST request
            const response = await axios.post('https://dungeon-gpt-api-9a9rj.ondigitalocean.app/create_room/', payload);

            // Navigate to the newly created room
            const newRoomId = response.data.game_ref_id;
            setIsLoading(false);
            navigate(`/rooms/${newRoomId}`);
        } catch (error) {
            setIsLoading(false);
            console.error("Failed to create room:", error);
        }
    };


    return (
        <Box px={[4, 8, 16]} pt={[4, 8, 16]} pb={[4, 8, 16]}>
            <VStack spacing={8} align="center">
                <Heading as="h2" size="xl">Featured Quests</Heading>
                <Flex direction={["column", "row"]} justify="space-between" wrap="wrap" w="100%">
                    {quests.map((quest, index) => (
                        <Box
                            key={index}
                            w={["100%", "30%"]}
                            p={4}
                            borderWidth="1px"
                            borderRadius="md"
                            overflow="hidden"
                        >
                            <VStack align="center" spacing={4}>
                                <Heading as="h3" size="md">{quest.title}</Heading>
                                <Image
                                    src={quest.cover_image}
                                    alt={quest.title}
                                    w="100%"
                                    maxH="500px"
                                    objectFit="contain"
                                    borderRadius="lg"
                                />
                                <Text textAlign="center">{quest.description}</Text>
                                <Divider/>
                                <Button bg="#FF4136" color="white" onClick={() => {
                                    setSelectedQuest(quest);
                                    onOpen();
                                }}>
                                    Start Quest
                                </Button>
                            </VStack>
                        </Box>
                    ))}
                </Flex>

                {/* Start Quest Modal */}
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay/>
                    <ModalContent>
                        <ModalHeader>Select Options for {selectedQuest?.title}</ModalHeader>
                        <ModalCloseButton/>
                        <ModalBody>
                            <FormControl>
                                <FormLabel>Select Character</FormLabel>
                                <Select
                                    placeholder="Select character"
                                    onChange={(e) => setSelectedCharacterId(e.target.value)}
                                >
                                    {characters.map((character) => (
                                        <option key={character.id} value={character.id}>
                                            {character.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl mt={4}>
                                <FormLabel>Experience Level</FormLabel>
                                <Select placeholder="Select experience level">
                                    <option value="experienced">Experienced</option>
                                </Select>
                            </FormControl>

                            <FormControl mt={4}>
                                <FormLabel>Party Size</FormLabel>
                                <Select placeholder="Select party size">
                                    <option value="solo">Solo</option>
                                </Select>
                            </FormControl>
                            {/* Centered Button */}
                            <Flex justifyContent="center" alignItems="center" mt={4}>
                                <Button bg="#FF4136" color="white" onClick={handleCreateRoom} isLoading={isLoading}>
                                    Launch DungeonGPT
                                </Button>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </VStack>
        </Box>
    );
};

export default FeaturedQuests;