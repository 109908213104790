import React, { useState, useCallback } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControl,
    FormLabel,
    Heading,
    HStack,
    Input,
    Link,
    Stack,
    Text,
    useToast,
    Spacer,
    useColorModeValue
} from '@chakra-ui/react';
import { signIn, signInWithEmail, signUpWithEmail, auth } from '../auth/firebase';
import { loadFull } from 'tsparticles';
import Particles from 'react-tsparticles';
import { useNavigate } from 'react-router-dom';
import Logo from "../components/Logo";

const SignInSignUp = () => {
    const [username, setUsername] = useState("");
    const [isSignIn, setIsSignIn] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const toast = useToast();
    const navigate = useNavigate();

    const handleSubmit = async event => {
        event.preventDefault();

        try {
            if (isSignIn) {
                try {
                    await signInWithEmail(email, password);
                    navigate(`/profile`);
                } catch (error) {
                    toast({
                        title: "An error occurred.",
                        description: error.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } else {
                try {
                    await signUpWithEmail(email, password, username);
                    navigate(`/profile`);
                } catch (error) {
                    toast({
                        title: "An error occurred.",
                        description: error.message,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            }
        } catch (error) {
            toast({
                title: "An error occurred.",
                description: error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const particlesInit = useCallback(async (engine) => {
        console.log(engine);
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        console.log(container);
    }, []);

    return (
        <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }} zIndex="10">
            <Particles id="tsparticles" url="/particles.json" init={particlesInit} loaded={particlesLoaded} style={{
                position: 'absolute',
                zIndex: 0,  // set a lower z-index for the particles
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
            }} />
            <Stack spacing="8">
                <Stack spacing="6">
                    <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
                        <Logo imageSrc="/dgpt_B11_crop.png" altText="Logo" />
                    </Stack>
                </Stack>
                <Box boxShadow="md" p={8} bg={useColorModeValue('white', 'gray.800')} rounded="lg" border="1px" borderColor="gray.300">
                    <Stack spacing="8" style={{ position: 'relative', zIndex: 1 }} textAlign="center">
                        <Heading size={{ base: 'xs', md: 'sm' }}>
                            {isSignIn ? 'Log in to your account' : 'Create your account'}
                        </Heading>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing="5">
                                {!isSignIn && (
                                    <FormControl>
                                        <FormLabel htmlFor="username">Username</FormLabel>
                                        <Input
                                            id="username"
                                            type="text"
                                            value={username}
                                            onChange={e => setUsername(e.target.value)}
                                            isRequired
                                        />
                                    </FormControl>
                                )}
                                <FormControl>
                                    <FormLabel htmlFor="email">Email</FormLabel>
                                    <Input
                                        id="email"
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        isRequired
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel htmlFor="password">Password</FormLabel>
                                    <Input
                                        id="password"
                                        type="password"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        isRequired
                                    />
                                </FormControl>
                                {isSignIn && (
                                    <HStack justify="space-between">
                                        <Checkbox defaultChecked>Remember me</Checkbox>
                                        <Button variant="text" size="sm" textDecoration="underline">
                                            Forgot password?
                                        </Button>
                                    </HStack>
                                )}
                                <Button type="submit" mt={4}>{isSignIn ? 'Sign In' : 'Sign Up'}</Button>
                            </Stack>
                        </form>
                        <Divider />
                        <Button onClick={signIn} mt={4}>Sign In with Google</Button>
                        <Text color="fg.muted" textDecoration="underline" cursor="pointer" onClick={() => setIsSignIn(!isSignIn)} mt={4} textAlign="center">
                            {isSignIn ? "Don't have an account? Sign up" : "Already have an account? Sign in"}
                        </Text>
                    </Stack>
                </Box>
            </Stack>
        </Container>
    );

};

export default SignInSignUp;
