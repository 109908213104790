import { Flex, Button, Box, Link, Text, Image, Grid, Icon, VStack, Heading, SimpleGrid, Center, Card, CardBody, CardFooter, Stack, Divider } from '@chakra-ui/react';
import { AiOutlineComment, AiFillBook, AiFillSound } from 'react-icons/ai'; // Example Icon
import Typist from 'react-typist-component';
import {signIn, signInWithEmail, signUpWithEmail, signUserOut} from "../auth/firebase";
import React, {useState} from "react";
import Logo from "../components/Logo";
import { auth, firestore } from '../auth/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import SignInSignUpModal from '../auth/SignInSignUpModal';
import { useNavigate } from 'react-router-dom';
import FeaturedQuests from "../components/FeaturedQuests";

const MyComponent = () => {
  return (
    <Typist typingDelay={100}>
      Roll a D20
      <br />
      <Typist.Delay ms={500} />
      <Typist.Backspace count={11} />
      <Typist.Delay ms={1000} />
      Roll to hit!
        <Typist.Delay ms={500} />
        <Typist.Backspace count={12} />
      <Typist.Delay ms={800} />
      Hello adventurer, my name is DungeonGPT.
        <br />
        Roll for initiative...
    </Typist>
  );
};


const Hero = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const navigate = useNavigate();

    return (
        <Flex direction="column" align="center" justify="center">
            <Flex py={20} align="center" justify="center" direction={["column", "row"]}>
                <VStack align="start" maxW="md" pr={[0, 8]} spacing={4}>
                    <Heading as="h1" size="2xl">
                        <MyComponent/>
                    </Heading>
                    <br/>
                    <Text fontSize="lg">
                        Experience the thrill of a creative quest guided by our AI Dungeon Master, powered by LLMs.
                    </Text>
                    <Text fontSize="lg">
                        We currently in a closed beta. To sign up for our beta, please{' '}
                        <Link
                            href="https://docs.google.com/forms/d/e/1FAIpQLSedZVDIUdqvEID-QmOMm6LON7pztyEI_q9mCGTq7anr2mH0aA/viewform"
                            color="blue.500">fill out this form</Link>.
                    </Text>
                    <Text fontSize="lg">
                        Follow us on <Link href="https://twitter.com/GptDungeon" color="blue.500">Twitter</Link> for updates
                        and releases!
                    </Text>
                    <Box w="100%">
                        <Center>
                            <Button bg="#FF4136" color="white" onClick={openModal} size="sm">Sign Up With Beta
                                Key</Button> {/* Custom red color */}
                            <SignInSignUpModal
                                isOpen={isModalOpen}
                                onClose={closeModal}
                                signIn={signIn}
                                signInWithEmail={signInWithEmail}
                                signUpWithEmail={signUpWithEmail}
                                navigate={navigate}
                            />
                        </Center>
                    </Box>
                </VStack>
                <Box maxW="650px" ml={[1, 8]}>
                    <Image src="/dnd_ai_dm_lp.png" alt="Hero Image" w="100%"/>
                </Box>
            </Flex>
        </Flex>
    );
};

const Features = () => (
  <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6} p={8}>
    <FeatureBox title="Dynamic Storytelling" icon={AiFillBook} />
    <FeatureBox title="AI Voice" icon={AiFillSound} />
    <FeatureBox title="Realistic Responses" icon={AiOutlineComment} />
  </Grid>
);

const FeatureBox = ({ title, icon }) => (
  <Box textAlign="center">
    <Icon as={icon} boxSize={8} />
    <Text fontWeight="bold">{title}</Text>
  </Box>
);

const Footer = () => (
  <Flex justify="space-between" align="center" p={4} bg="gray.700">
    <Box>© 2023 DungeonGPT</Box>
    <Box>
      <Link p={2} href="#">Privacy Policy</Link>
      <Link p={2} href="#">Terms of Service</Link>
      <Link p={2} href="#">Contact Us</Link>
    </Box>
  </Flex>
);

const LandingPage = () => (
  <>
    <Hero />
    <Features />
    <FeaturedQuests />
    <Footer />
  </>
);

export default LandingPage;
