import { extendTheme } from '@chakra-ui/react';

const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
};

const theme = extendTheme({
    config,
    colors: {
        gray: {
            800: "#1f2029",
            900: "#18181b",
        }
    }
});

export default theme;
