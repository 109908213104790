import React from 'react';
import './EllipsisLoading.css';

const EllipsisLoading = () => {
    return (
        <div className="ellipsis-loading">
            <span className="dot">.</span>
            <span className="dot">.</span>
            <span className="dot">.</span>
        </div>
    );
};

export default EllipsisLoading;
